import React from "react";

import icon1 from "../../assets/services/image1.png";
import icon2 from "../../assets/services/image2.png";
import icon3 from "../../assets/services/image3.png";
import aboutTextButton from "../../assets/about/aboutTextButton.png";

const WhyWizardHireSection = () => {
  return (
    <div className=" text-white py-12 px-6">
      <div className="container mx-auto max-w-screen-xl text-center">
        {/* Main Title */}
        <h2 className="text-5xl font-bold mb-8">
          <span className="font-cinzel">W</span>hy
          <span className="font-cinzel"> W</span>izardHire is
          <span className="font-cinzel"> D</span>ifferent:
        </h2>

        {/* Feature Section */}
        <div className="text-3xl mb-6">
          {/* Feature 1 */}
          <div className="flex items-start mb-3">
            <img
              src={icon1}
              alt="Bias-Free Hiring"
              className="w-20 h-20 mr-4"
            />
            <div>
              <span>Bias-Free Hiring: </span>
              Traditional job boards often focus on networking or profiles, but
              WizardHire’s AI eliminates unconscious bias, providing a fairer
              and more objective evaluation of candidates.
            </div>
          </div>

          {/* Feature 2 */}
          <div className="flex items-start mb-3">
            <img
              src={icon2}
              alt="Streamlined & Cost-Effective"
              className="w-20 h-20 mr-4"
            />
            <div>
              <span> Streamlined & Cost-Effective: </span>
              For a transparent setup fee of $199 and just $39 per job posting
              valid for 20 days, WizardHire offers a powerful SaaS solution
              that’s not only efficient but also cost-effective. No hidden fees,
              just seamless recruitment.
            </div>
          </div>

          {/* Feature 3 */}
          <div className="flex items-start mb-3">
            <img
              src={icon3}
              alt="HR Innovation Focus"
              className="w-20 h-20 mr-4"
            />
            <div>
              <span>HR Innovation Focus: </span>
              Unlike general job boards, WizardHire is designed to disrupt and
              elevate HR practices with technology and personalized support,
              helping companies optimize their talent acquisition process.
            </div>
          </div>
        </div>

        {/* Highlighted Text Box */}
        <div
          className="w-full p-10 text-center"
          style={{
            backgroundImage: `url(${aboutTextButton})`,
            backgroundSize: "100% 100%",
          }}
        >
          <p className="text-3xl">
            From the first steps of developing this platform, we’ve been
            dedicated to creating something truly unique—an affordable,
            AI-driven recruiting tool that cuts through the noise and makes
            hiring easier, faster, and fairer for all.
          </p>
          <p className="text-3xl mt-4">
            Join us in transforming the future of work, one hire at a
            time—because at WizardHire, we believe that great talent knows no
            boundaries.
          </p>
        </div>
        {/* Footer Section */}
        <div className="text-3xl mt-5">
          <p>
            WizardHire occupies space in Winnipeg, Manitoba, which is located on
            Treaty 1 territory. This land is the homelands and traditional
            territory of the Anishinaabeg, Ininew/Nehiyaw, Anisininew, Dakota
            Oyate, and Dene peoples, and homelands of the Red River Metis. We
            give special thanks to Shoal Lake 40 First Nation in Treaty 3, which
            is where the water that Winnipeg benefits from is sourced. We
            acknowledge the harms done to Indigenous peoples as a result of
            colonization, and we dedicate ourselves to moving forward in
            partnership with Indigenous communities in a spirit of
            reconciliation and collaboration.
          </p>
        </div>
      </div>
    </div>
  );
};

export default WhyWizardHireSection;
