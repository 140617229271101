import React, { useState, useEffect } from "react";
import axios from "axios";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import DOMPurify from "dompurify";

const Interview = () => {
  const params = useParams();
  const [jobDescription, setJobDescription] = useState("");
  const [userResponse, setUserResponse] = useState("");
  const [chatHistory, setChatHistory] = useState([]);
  const [jobTitle, setJobTitle] = useState("");
  const [answerCount, setAnswerCount] = useState(0);
  const [interviewResult, setInterviewResult] = useState("");
  const [result, setResult] = useState(null);
  const [feedBacks, setFeedBacks] = useState("");
  const location = useLocation();
  const [applicantId, setApplicantId] = useState(
    location.state?.applicantId || ""
  );
  const navigate = useNavigate();

  const vacancyId = params.id;
  const handleAskQuestion = async () => {
    try {
      const responses = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/v1/ai/generateFollowUpQuestion`,
        {
          jobDescription,
          userResponse,
          chatHistory,
        }
      );
      const botMessage = responses.data.question.content;
      if (answerCount === 3) {
        setChatHistory((prevHistory) => [
          ...prevHistory,

          {
            sender: "User",
            message: userResponse,
          },
        ]);
      } else {
        setChatHistory((prevHistory) => [
          ...prevHistory,

          {
            sender: "User",
            message: userResponse,
          },
          {
            sender: "Interviewer",
            message: botMessage,
          },
        ]);
      }

      setAnswerCount((prevCount) => prevCount + 1);
      setUserResponse("");
      console.log("answer", answerCount);
      if (answerCount === 3) {
        const finalEvalution = await axios.post(
          `${process.env.REACT_APP_API_URL}/api/v1/ai/evaluate`,
          {
            jobDescription,
            chatHistory: [
              ...chatHistory,
              {
                sender: "Interviewer",
                message: botMessage,
              },
              {
                sender: "User",
                message: userResponse,
              },
            ],
          }
        );
        const evaluation = finalEvalution?.data?.evaluation;
        const feedback = finalEvalution?.data?.summaryFeedback;
        setResult(evaluation);
        setInterviewResult(evaluation);
        setFeedBacks(feedback);

        const { data } = await axios.post(
          `${process.env.REACT_APP_API_URL}/api/v1/ai/createInterviewResult`,
          {
            applicantId,
            vacancyId,
            score: evaluation,
            feedback: feedback,
          }
        );

        if (data?.success) {
          toast.success("Your Application has been submitted");
          navigate("/dashboard/candidate/applicant");
        }
      }
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };
  const generateInitialQuestion = async (
    jobTitle,
    jobType,
    experiencelevel,
    experienceUnit,
    skill,
    jobDescription
  ) => {
    try {
      console.log(jobDescription)
      const responses = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/v1/ai/getInitialQuestion`,
        {
          jobTitle,
          jobType,
          experiencelevel,
          experienceUnit,
          skill,
          jobDescription,
          userResponse,
          chatHistory,
        }
      );
      const botMessage = responses.data.question.content;
      setChatHistory((prevHistory) => [
        ...prevHistory,
        {
          sender: "Interviewer",
          message: botMessage,
        },
      ]);
      setAnswerCount((prevCount) => prevCount + 1);
      setUserResponse("");
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };
  const getVacancy = async () => {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/v1/vacancy/getSingleVacancy/${params.id}`
      );
      if (data?.success === true) {
        const jobTitle = data.singleVacancy.jobTitle;
        const jobType = data.singleVacancy.jobType;
        const experiencelevel = data.singleVacancy.experiencelevel.value || 0;
        const experienceUnit = data.singleVacancy.experiencelevel.unit;
        const skill = data.singleVacancy.skills;
        const jobDescription = DOMPurify.sanitize(
          data.singleVacancy.jobDescription
        );

        setJobTitle(jobTitle);
        generateInitialQuestion(
          jobTitle,
          jobType,
          experiencelevel,
          experienceUnit,
          skill,
          jobDescription
        );
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    if (applicantId) {
      toast.success("All the best for the interview");
      getVacancy();
    }
  }, [applicantId]);

  return (
    <div className="flex flex-col h-screen bg-gray-100">
      <header className="bg-blue-500 text-white p-4">
        <h1 className="text-3xl">
          Interview - <span>{jobTitle}</span>
        </h1>
      </header>
      <main className="flex-1 overflow-y-auto p-4">
        {chatHistory.map((entry, index) => (
          <div
            key={index}
            className={`mb-2 p-2 rounded-lg ${
              entry.sender === "User"
                ? "bg-blue-100 self-end"
                : "bg-gray-300 self-start"
            }`}
          >
            <strong>{entry.sender}:</strong> {entry.message}
          </div>
        ))}
      </main>
      <footer className="p-4 bg-white border-t border-gray-300">
        <form
          className="flex"
          onSubmit={(e) => {
            e.preventDefault(); // Prevent default form submission
            handleAskQuestion(); // Call your function to handle the question submission
          }}
        >
          <input
            type="text"
            className="flex-1 p-2 border border-gray-300 rounded-lg"
            value={userResponse}
            onChange={(e) => setUserResponse(e.target.value)}
            disabled={result !== null}
          />
          <button
            type="submit" // Change to submit type to trigger form submission
            className={`ml-2 p-2 rounded-lg ${
              result !== null
                ? "bg-red-500 text-white"
                : "bg-blue-500 text-white"
            }`}
            disabled={result !== null} // Optionally disable button if result is not null
          >
            Send
          </button>
        </form>
      </footer>
    </div>
  );
};

export default Interview;
