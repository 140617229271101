import React from "react";
import { useUserRole } from "../../../context/userRoleContext";
import whiteStar from "../../../assets/images/whiteStar.png";
import button from "../../../assets/images/button.png";
import pricingCard from "../../../assets/services/pricingCard.png";
import messageIcon from "../../../assets/services/messageIcon.png";

const employerPricingPlans = [
  {
    title: "CUSTOM AI SETUP",
    price: "$199.95",
    description: "Tailor-made AI designed to meet your hiring needs.",
    details: [
      "We build your AI based on proven HR models or collaborate with you to create a unique solution.",
      "One-time setup fee ensures you're ready to post jobs with ease.",
    ],
    buttonText: "GET STARTED",
    additionalInfo: "SETUP FEE",
  },
  {
    title: "JOB POSTING",
    price: "$39.95",
    description: "Post your job openings and start receiving top candidates.",
    details: [
      "Each job post lasts for 20 days and reaches qualified candidates.",
      "Easily manage all applications from the dashboard.",
    ],
    buttonText: "GET STARTED",
    additionalInfo: "PER POST (20 DAYS)",
  },
  {
    title: "CUSTOM & ENTERPRISE  SOLUTIONS",
    icon: true, // Indicates the presence of an icon
    description: "Custom solutions for large-scale hiring needs.",
    details: [
      "Our team will work with you to create a custom solution that meets your unique hiring needs.",
    ],
    buttonText: "CONTACT US",
  },
];

const stepsCandidateData = [
  {
    id: 1,
    title: "STEP 1",
    description:
      "Click on the WizardHire link or scan the QR code and login to your WizardHire account.",
    backgroundImage: pricingCard,
  },
  {
    id: 2,
    title: "STEP 2",
    description: "Upload your resume.",
    backgroundImage: pricingCard,
  },
  {
    id: 3,
    title: "STEP 3",
    description: "Ace the interview and receive feedback",
    backgroundImage: pricingCard,
  },
];

const Pricing = () => {
  const { userRole } = useUserRole();
  const pricingPlans =
    userRole === "recruiter" ? employerPricingPlans : stepsCandidateData;

  return (
    <div className="container mx-auto flex flex-col my-10 py-10">
      <div className="flex flex-row text-center items-center justify-center">
        <img src={whiteStar} alt="white Star" className="w-8 mr-5" />
        <h1 className="text-white text-4xl">
          <span className="font-cinzel text-5xl">P</span>RICING{" "}
        </h1>
        <img src={whiteStar} alt="white start" className="w-8 ml-5" />
      </div>

      <div className="w-full flex flex-wrap ">
        <div className="w-full flex flex-wrap justify-between ">
          {pricingPlans.map((plan, index) => {
            return (
              <div key={index} className="text-white w-[300px] text-center">
                <div
                  className="text-2xl flex justify-center items-center"
                  style={{
                    backgroundImage: `url(${button})`,
                    backgroundSize: "100% 100%",
                    padding: "40px 60px",
                    minHeight: "180px",
                  }}
                >
                  {plan.title}
                </div>

                <div
                  className="shadow-lg p-8 text-center"
                  style={{
                    backgroundImage: `url(${pricingCard})`,
                    backgroundSize: "100% 100%",
                    // width: "300px",
                    height: "466px",
                  }}
                >
                  {plan.price && (
                    <p className="text-4xl  text-white">{plan.price}</p>
                  )}
                  {plan.icon && (
                    <div className="flex items-center justify-center ">
                      {/* <h1>Hello Icons</h1> */}
                      <img
                        src={messageIcon}
                        alt="Message Icon"
                        className="h-12 mb-10"
                      />
                    </div>
                  )}
                  {plan.additionalInfo && (
                    <p className="text-white mb-6">{plan.additionalInfo}</p>
                  )}
                  <div className="flex justify-center items-center">
                    <button className="custom-button flex bg-white text-pure-black font-bold text-xl py-2 px-4 rounded-xl">
                      {plan.buttonText}
                    </button>
                  </div>

                  <p className="text-white mt-6">{plan.description}</p>
                  {plan.details.map((detail, detailIndex) => (
                    <p key={detailIndex} className="text-white mt-4">
                      {detail}
                    </p>
                  ))}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Pricing;
