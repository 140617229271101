import React from "react";
import treasure from "../../../assets/services/candidate/treasure.png";
import mirror from "../../../assets/services/candidate/mirror.png";
import ai from "../../../assets/services/candidate/ai.png";
import globe from "../../../assets/services/candidate/globe.png";
import logoImage from "../../../assets/services/logo.png";
import logo from "../../../assets/features/logo.png";

export const ServiceCandidateFeatures = () => {
  return (
    <>
      <div className="flex flex-col md:flex-row justify-center items-center relative md:mb-10 mb-6">
        {/* Image container */}
        <div className="md:mb-10 z-10">
          <img
            src={logoImage}
            alt="Company Logo"
            className="w-24 h-24 object-contain md:w-32 md:h-32"
          />
        </div>

        {/* Heading with responsive negative margin to create overlap */}
        <h2
          className="uppercase text-2xl md:text-4xl text-white font-cinzel font-bold text-center md:mt-10 relative md:-ml-4 md:-ml-12"
          style={{
            textShadow: "0px 4px 16px rgba(255, 255, 255, 0.70)",
          }}
        >
          Your Dream Job Awaits! Apply <br /> Anywhere, Anytime.
        </h2>
      </div>

      <div>
        <h1
          className="font-cinzel text-xl md:text-2xl text-white font-bold text-center"
          style={{
            textShadow: "0px 2px 24px rgba(255, 255, 255, 0.60)",
            WebkitTextStrokeWidth: "1px",
            WebkitTextStrokeColor: "rgba(255, 182, 54, 0.40)",
          }}
        >
          Key Features:
        </h1>
        <div>
          <div className="flex flex-col lg:flex-row-reverse md:flex-row-reverse  items-center justify-between">
            <div className="flex-1 mb-4 lg:mb-0  flex justify-end items-center ">
              <img
                src={treasure}
                alt="treasure"
                className="rounded-lg shadow-md"
              />
            </div>

            <div
              className="flex-1 flex-col p-8 justify-center items-start gap-2.5 rounded-[24px] border-4 border-[#061C3E] "
              style={{
                background:
                  "radial-gradient(117% 104.57% at 46.9% 0%, rgba(14, 93, 173, 0.90) 0%, rgba(11, 105, 94, 0.90) 60%, rgba(12, 44, 62, 0.90) 97%)",
                boxShadow:
                  "0px 4px 20px 0px rgba(255, 255, 255, 0.25), 0px 4px 4px 0px #6BF0FC inset, 0px 2px 12px 0px #FFF inset",
              }}
            >
              <div className="flex gap-3 ">
                <img
                  src={logo}
                  alt="company logo"
                  className="w-[24px] h-[24px]"
                />
                <h3 className="uppercase text-xl font-bold text-white mb-2 text-center">
                  Free for Applicants:
                </h3>
              </div>
              <p className="text-white">
                Applying for jobs is completely free with WizardHire. Submit
                your resume and experience a seamless application process
                powered by AI.
              </p>
            </div>
          </div>
          <div className="flex flex-col lg:flex-row md:flex-row items-center justify-between ">
            <div className="flex-1 mb-4 lg:mb-0 ">
              <img src={mirror} alt="mirror" className="rounded-lg shadow-md" />
            </div>
            <div
              className="flex-1 flex-col  p-8 justify-center items-start gap-2.5 rounded-[24px] border-4 border-[#061C3E] bg-green-50"
              style={{
                background:
                  "radial-gradient(117% 104.57% at 46.9% 0%, rgba(14, 93, 173, 0.90) 0%, rgba(11, 105, 94, 0.90) 60%, rgba(12, 44, 62, 0.90) 97%)",
                boxShadow:
                  "0px 4px 20px 0px rgba(255, 255, 255, 0.25), 0px 4px 4px 0px #6BF0FC inset, 0px 2px 12px 0px #FFF inset",
              }}
            >
              <div className="flex gap-3">
                <img
                  src={logo}
                  alt="company logo"
                  className="w-[24px] h-[24px]"
                />
                <h3 className="uppercase text-xl font-bold text-white mb-2 text-center">
                  Personalized Application Portal:
                </h3>
              </div>
              <p className="text-white">
                Manage all your job applications in one place. Track the status
                of your applications, receive instant feedback from employers,
                and get notified when there’s a perfect match.
              </p>
            </div>
          </div>
          <div className="flex flex-col lg:flex-row-reverse md:flex-row-reverse  items-center justify-between">
            <div className="flex-1 mb-4 lg:mb-0  flex justify-end items-center">
              <img
                src={ai}
                alt="Door portal"
                className="rounded-lg shadow-md"
              />
            </div>

            <div
              className="flex-1 flex-col p-8 justify-center items-start gap-2.5 rounded-[24px] border-4 border-[#061C3E] "
              style={{
                background:
                  "radial-gradient(117% 104.57% at 46.9% 0%, rgba(14, 93, 173, 0.90) 0%, rgba(11, 105, 94, 0.90) 60%, rgba(12, 44, 62, 0.90) 97%)",
                boxShadow:
                  "0px 4px 20px 0px rgba(255, 255, 255, 0.25), 0px 4px 4px 0px #6BF0FC inset, 0px 2px 12px 0px #FFF inset",
              }}
            >
              <div className="flex gap-3 ">
                <img
                  src={logo}
                  alt="company logo"
                  className="w-[24px] h-[24px]"
                />
                <h3 className="text-xl font-bold text-white mb-2 text-center">
                  AI-Powered Matching:
                </h3>
              </div>
              <p className="text-white">
                Our AI magic works behind the scenes to match you with jobs that
                best fit your skills, experience, and career goals. No more
                endless scrolling — let the jobs come to you.
              </p>
            </div>
          </div>
          <div className="flex flex-col lg:flex-row md:flex-row items-center justify-between ">
            <div className="flex-1 mb-4 lg:mb-0 ">
              <img src={globe} alt="globe" className="rounded-lg shadow-md" />
            </div>
            <div
              className="flex-1 flex-col  p-8 justify-center items-start gap-2.5 rounded-[24px] border-4 border-[#061C3E] bg-green-50"
              style={{
                background:
                  "radial-gradient(117% 104.57% at 46.9% 0%, rgba(14, 93, 173, 0.90) 0%, rgba(11, 105, 94, 0.90) 60%, rgba(12, 44, 62, 0.90) 97%)",
                boxShadow:
                  "0px 4px 20px 0px rgba(255, 255, 255, 0.25), 0px 4px 4px 0px #6BF0FC inset, 0px 2px 12px 0px #FFF inset",
              }}
            >
              <div className="flex gap-3">
                <img
                  src={logo}
                  alt="company logo"
                  className="w-[24px] h-[24px]"
                />
                <h3 className="uppercase text-xl font-bold text-white mb-2 text-center">
                  Global Opportunities:
                </h3>
              </div>
              <p className="text-white">
                Whether you’re looking to apply locally or internationally, we
                bring opportunities from all over the world to your fingertips.
                Expand your horizons and apply with confidence.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
