import axios from "axios";
import React, { useEffect, useState } from "react";
import Modal from "react-modal";

Modal.setAppElement("#root");

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "500px",
    padding: "20px",
    transition: "opacity 0.2s ease-in-out",
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.75)",
    zIndex: "1000",
    transition: "opacity 0.2s ease-in-out",
  },
};

const AdminEmployersInformation = () => {
  const [employers, setEmployers] = useState([]);
  const [editModalIsOpen, setEditModalIsOpen] = useState(false);
  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);
  const [selectedEmployer, setSelectedEmployer] = useState(null);
  const [formData, setFormData] = useState({
    companyName: "",
    firstName: "",
    lastName: "",
    email: "",
  });
  const [typedCompanyName, setTypedCompanyName] = useState("");

  const displayEmployers = async () => {
    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/v1/user/displayCompany`
      );
      if (data.success) {
        setEmployers(data.company);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const openEditModal = (employer) => {
    setSelectedEmployer(employer);
    setFormData({
      companyName: employer.companyName || "",
      firstName: employer.firstName || "",
      lastName: employer.lastName || "",
      email: employer.email || "",
    });
    setEditModalIsOpen(true);
  };

  const closeEditModal = () => {
    setEditModalIsOpen(false);
    setSelectedEmployer(null);
  };

  const openDeleteModal = (employer) => {
    setSelectedEmployer(employer);
    setTypedCompanyName("");
    setDeleteModalIsOpen(true);
  };

  const closeDeleteModal = () => {
    setDeleteModalIsOpen(false);
    setSelectedEmployer(null);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSave = async () => {
    try {
      const { data } = await axios.put(
        `${process.env.REACT_APP_API_URL}/api/v1/user/updateCompany`,
        { ...formData, id: selectedEmployer._id }
      );
      if (data.success) {
        setEmployers((prevEmployers) =>
          prevEmployers.map((emp) =>
            emp._id === selectedEmployer._id ? { ...emp, ...formData } : emp
          )
        );
        closeEditModal();
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleDelete = async () => {
    if (typedCompanyName === selectedEmployer.companyName) {
      try {
        const { data } = await axios.delete(
          `${process.env.REACT_APP_API_URL}/api/v1/user/deleteCompany/${selectedEmployer._id}`
        );
        if (data.success) {
          setEmployers((prevEmployers) =>
            prevEmployers.filter((emp) => emp._id !== selectedEmployer._id)
          );
          closeDeleteModal();
        }
      } catch (error) {
        console.error(error);
      }
    } else {
      alert(
        "Company name does not match. Please type the correct company name to confirm deletion."
      );
    }
  };

  useEffect(() => {
    displayEmployers();
  }, []);

  return (
    <>
      <div className="bg-gray-100 flex items-center justify-center">
        <div className="bg-white p-8 rounded shadow-md w-full max-w-4xl">
          <h2 className="text-2xl font-bold mb-6">Company Information</h2>
          <div className="overflow-x-auto">
            <table className="min-w-full bg-white">
              <thead>
                <tr>
                  <th className="py-2 px-4 border-b-2 border-gray-300">
                    Company Name
                  </th>
                  <th className="py-2 px-4 border-b-2 border-gray-300">
                    First Name
                  </th>
                  <th className="py-2 px-4 border-b-2 border-gray-300">
                    Last Name
                  </th>
                  <th className="py-2 px-4 border-b-2 border-gray-300">
                    Email
                  </th>
                  <th className="py-2 px-4 border-b-2 border-gray-300">
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {employers.map((employer, index) => (
                  <tr key={index}>
                    <td className="py-2 px-4 border-b border-gray-300">
                      {employer.companyName}
                    </td>
                    <td className="py-2 px-4 border-b border-gray-300">
                      {employer.firstName}
                    </td>
                    <td className="py-2 px-4 border-b border-gray-300">
                      {employer.lastName}
                    </td>
                    <td className="py-2 px-4 border-b border-gray-300">
                      {employer.email}
                    </td>
                    <td className="py-2 px-4 border-b border-gray-300 flex gap-2">
                      <button
                        className="bg-blue-700 hover:bg-blue-800 p-2 text-white border"
                        onClick={() => openEditModal(employer)}
                      >
                        Edit
                      </button>
                      <button
                        className="bg-red-700 hover:bg-red-800 p-2 text-white border"
                        onClick={() => openDeleteModal(employer)}
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <Modal
        isOpen={editModalIsOpen}
        onRequestClose={closeEditModal}
        contentLabel="Edit Employer"
        style={customStyles}
      >
        <h2 className="text-2xl font-bold mb-6">Edit Employer</h2>
        <form>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2">
              Company Name
            </label>
            <input
              type="text"
              name="companyName"
              value={formData.companyName}
              onChange={handleChange}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2">
              First Name
            </label>
            <input
              type="text"
              name="firstName"
              value={formData.firstName}
              onChange={handleChange}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2">
              Last Name
            </label>
            <input
              type="text"
              name="lastName"
              value={formData.lastName}
              onChange={handleChange}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2">
              Email
            </label>
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            />
          </div>
          <div className="flex items-center justify-between">
            <button
              type="button"
              onClick={handleSave}
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            >
              Save
            </button>
            <button
              type="button"
              onClick={closeEditModal}
              className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            >
              Cancel
            </button>
          </div>
        </form>
      </Modal>

      <Modal
        isOpen={deleteModalIsOpen}
        onRequestClose={closeDeleteModal}
        contentLabel="Delete Employer"
        style={customStyles}
      >
        <h2 className="text-2xl font-bold mb-6">Delete Employer</h2>
        <p>
          Are you sure you want to delete the company{" "}
          <strong>{selectedEmployer?.companyName}</strong>? This action cannot
          be undone.
        </p>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2">
            Type the company name to confirm:
          </label>
          <input
            type="text"
            value={typedCompanyName}
            onChange={(e) => setTypedCompanyName(e.target.value)}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          />
        </div>
        <div className="flex items-center justify-between">
          <button
            type="button"
            onClick={handleDelete}
            className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          >
            Delete
          </button>
          <button
            type="button"
            onClick={closeDeleteModal}
            className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          >
            Cancel
          </button>
        </div>
      </Modal>
    </>
  );
};

export default AdminEmployersInformation;
