import React, { useState } from "react";
import whiteStar from "../../../assets/images/whiteStar.png";
import button from "../../../assets/images/button.png";
import buttonHover from "../../../assets/images/buttonHover.png";
import { useNavigate } from "react-router-dom";
const FinalCta = () => {
  const navigate = useNavigate();
  const [isButtonHovered, setIsButtonHovered] = useState(false);

  return (
    <div className="container mx-auto max-w-screen-lg text-white">
      <div className="flex flex-col items-center text-center pb-12">
        <div className="flex flex-row text-center items-center justify-center">
          <img src={whiteStar} alt="" className="w-8 mr-5" />
          <h1 className="text-white text-4xl">
            <span className="font-cinzel text-5xl">S</span>O{" "}
            <span className="font-cinzel text-5xl">W</span>HAT{" "}
            <span className="font-cinzel text-5xl">A</span>RE{" "}
            <span className="font-cinzel text-5xl">Y</span>OU <br />
            <span className="font-cinzel text-5xl">W</span>AITING{" "}
            <span className="font-cinzel text-5xl">F</span>OR ?
          </h1>
          <img src={whiteStar} alt="" className="w-8 ml-5" />
        </div>

        <button
          className="md:w-fit mx-auto bg-cover text-xl font-semibold wizard-background "
          onMouseEnter={() => {
            setIsButtonHovered(true);
          }}
          onMouseLeave={() => {
            setIsButtonHovered(false);
          }}
          style={{
            backgroundImage: `url(${isButtonHovered ? buttonHover : button})`,
            padding: "15px 40px",
          }}
        >
          START NOW
        </button>
      </div>
    </div>
  );
};

export default FinalCta;
