import React, { useState } from "react";
import HeaderImage from "../../../assets/images/Header.png";
import NavbarLogoImage from "../../../assets/images/logo.png";
import NavbarLogoImage2 from "../../../assets/images/logoGlow.png";
import { NavLink, Link } from "react-router-dom";
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";
import "./Header.css";

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <>
      <nav
        className="py-4 px-20 shadow-md rounded-b-2xl bg-cover bg-center"
        style={{
          backgroundImage: `url(${HeaderImage})`,
        }}
      >
        <div className=" flex justify-between items-center">
          <div>
            <Link to="/home" className="text-white">
              <div className="animate-imageSwap" style={{ width: "200px" }}>
                <img src={NavbarLogoImage} alt="Logo 1" />
                <img src={NavbarLogoImage2} alt="Logo 2" />
              </div>
            </Link>
          </div>
          {/* Breadcrumb icon for mobile */}
          <div className="md:hidden">
            <button onClick={toggleMenu}>
              <AiOutlineMenu className="w-8 h-8 text-primary" />
            </button>
          </div>
          {/* Desktop Menu - Centered Navigation */}
          {/* Sign In Button */}
          <div className="hidden font-futura md:flex text-xl md:text-sm lg:text-2xl space-x-12 font-bold text-primary  items-center">
            <NavLink
              to="/home"
              className={({ isActive }) =>
                `  ${
                  isActive ? "text-green-400" : "text-primary"
                } hover:text-green-400`
              }
            >
              <span className="text-3xl font-cinzel">H</span>OME
            </NavLink>
            <NavLink
              to="/about-us"
              className={({ isActive }) =>
                ` ${
                  isActive ? "text-green-400" : "text-primary"
                } hover:text-green-400`
              }
            >
              <span className="text-3xl font-cinzel">A</span>BOUT US
            </NavLink>
            <NavLink
              to="/service"
              className={({ isActive }) =>
                ` ${
                  isActive ? "text-green-400" : "text-primary"
                } hover:text-green-400`
              }
            >
              <span className="text-3xl font-cinzel">S</span>ERVICES
            </NavLink>

            <NavLink
              to="/"
              className={({ isActive }) =>
                `  ${
                  isActive ? "text-green-400" : "text-primary"
                } hover:text-green-400`
              }
            >
              <span className="text-3xl font-cinzel">M</span>ORE
            </NavLink>
            <NavLink
              to="/signup"
              className="custom-button border border-rounded"
            >
              <span className="text-3xl font-cinzel">P</span>ortal
            </NavLink>
          </div>
          {/* Mobile Menu */}
          {isMenuOpen && (
            <div className="fixed inset-0 bg-black bg-opacity-80 z-40">
              <div className="fixed left-0 top-0 w-3/4 h-full p-6 z-20 bg-[rgba(10, 10, 10, 0.80)] rounded-tr-2xl shadow-text-custom">
                <div className="flex flex-row justify-end">
                  <button className="text-primary mb-4" onClick={toggleMenu}>
                    <AiOutlineClose className="text-primary" />
                  </button>
                </div>
                <nav className="flex flex-col space-y-4 text-primary">
                  <NavLink
                    to="/home"
                    className={({ isActive }) =>
                      `font-cinzel ${
                        isActive ? "text-green-400" : "text-primary"
                      }`
                    }
                    onClick={toggleMenu}
                  >
                    HOME
                  </NavLink>
                  <NavLink
                    to="/about-us"
                    className={({ isActive }) =>
                      `font-cinzel ${
                        isActive ? "text-green-400" : "text-primary"
                      }`
                    }
                    onClick={toggleMenu}
                  >
                    ABOUT US
                  </NavLink>
                  <NavLink
                    to="/service"
                    className={({ isActive }) =>
                      `font-cinzel ${
                        isActive ? "text-green-400" : "text-primary"
                      }`
                    }
                    onClick={toggleMenu}
                  >
                    SERVICES
                  </NavLink>

                  <NavLink
                    to="/"
                    className={({ isActive }) =>
                      `font-cinzel ${
                        isActive ? "text-green-400" : "text-primary"
                      }`
                    }
                    onClick={toggleMenu}
                  >
                    MORE
                  </NavLink>

                  <button className="custom-button ">Sign in</button>
                </nav>
              </div>
            </div>
          )}
        </div>
      </nav>
    </>
  );
};

export default Header;
