import React, { useState } from "react";
import recruiter from "../../../assets/hero/recruiter.png";
import applicant from "../../../assets/hero/applicant.png";
import girl from "../../../assets/hero/girl.png";
import button from "../../../assets/images/button.png";
import buttonHover from "../../../assets/images/buttonHover.png";
import { useUserRole } from "../../../context/userRoleContext";

const HeroSection = () => {
  const [isButtonHovered, setIsButtonHovered] = useState(false);
  const { userRole } = useUserRole();
  const heroSectionImage = userRole === "recruiter" ? recruiter : applicant;
  return (
    <>
      <main className="container mx-auto flex flex-col items-center justify-center md:mb-20 text-sm md:text-2xl pt-3">
        <div className="relative">
          <div className="w-full overflow-hidden rounded-md shadow-lg mx-auto">
            <img
              src={heroSectionImage}
              alt="Diverse Office Team"
              className="w-full h-full object-cover "
            />
          </div>

          <div className="absolute bottom-0 right-[2rem] flex mb-5">
            <div className="text-center shadow-lg text-white">
              {userRole === "recruiter" ? (
                <h2
                  className=" px-4 py-3 mb-2 font-bold  rounded-tl-[120px] rounded-tr-none rounded-br-none rounded-bl-[120px] overflow-hidden relative"
                  style={{
                    backgroundImage: `url(${button})`,
                    backgroundPosition: "center",
                    height: "auto",
                    wordSpacing: "8px",
                  }}
                >
                  <span className="text-3xl font-cinzel">S</span>UMMON{" "}
                  <span className="text-3xl font-cinzel">Y</span>OUR{" "}
                  <span className="text-3xl font-cinzel">P</span>ERFECT{" "}
                  <span className="text-3xl font-cinzel">T</span>EAM{" "}
                </h2>
              ) : (
                <h2
                  className=" px-4 py-3 mb-2 font-bold  rounded-tl-[120px] rounded-tr-none rounded-br-none rounded-bl-[120px] overflow-hidden relative"
                  style={{
                    backgroundImage: `url(${button})`,
                    backgroundPosition: "center",
                    height: "auto",
                    wordSpacing: "8px",
                  }}
                >
                  <span className="text-3xl font-cinzel">F</span>IND{" "}
                  <span className="text-3xl font-cinzel">Y</span>OUR{" "}
                  <span className="text-3xl font-cinzel">P</span>ERFECT{" "}
                  <span className="text-3xl font-cinzel">J</span>OB{" "}
                </h2>
              )}

              <button
                onMouseEnter={() => {
                  setIsButtonHovered(true);
                }}
                onMouseLeave={() => {
                  setIsButtonHovered(false);
                }}
                className="px-10 py-4 rounded-md"
                style={{
                  backgroundImage: `url(${
                    isButtonHovered ? buttonHover : button
                  })`,
                  backgroundSize: "100% 100%",
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                }}
              >
                <span className="text-4xl font-cinzel">S</span>TART
              </button>
            </div>
          </div>

          {/* Keep girl image absolute within the parent container */}
          <img
            src={girl}
            alt="Anime Wizard"
            className="hidden md:block absolute bottom-[-5rem] left-[10rem] transform -translate-x-1/2 h-[30rem]"
          />
        </div>
      </main>
    </>
  );
};

export default HeroSection;
