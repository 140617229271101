import React from "react";
import { useUserRole } from "../../../context/userRoleContext";
import whiteStar from "../../../assets/images/whiteStar.png";
import employerCard1 from "../../../assets/works/employerCard1.png";
import employerCard2 from "../../../assets/works/employerCard2.png";
import employerCard3 from "../../../assets/works/employerCard3.png";
import applicantCard1 from "../../../assets/works/applicantCard1.png";
import applicantCard2 from "../../../assets/works/applicantCard2.png";
import applicantCard3 from "../../../assets/works/applicantCard3.png";

const stepsEmployerData = [
  {
    id: 1,
    title: "STEP 1",
    description: "Login to WizardHire and create a job posting.",
    backgroundImage: employerCard1,
  },
  {
    id: 2,
    title: "STEP 2",
    description: "Set the criteria for your ideal employee.",
    backgroundImage: employerCard2,
  },
  {
    id: 3,
    title: "STEP 3",
    description:
      "Receive a link and a QR code, and copy-paste it anywhere you like.",
    backgroundImage: employerCard3,
  },
];

const stepsCandidateData = [
  {
    id: 1,
    title: "STEP 1",
    description:
      "Click on the WizardHire link or scan the QR code and login to your WizardHire account.",
    backgroundImage: applicantCard1,
  },
  {
    id: 2,
    title: "STEP 2",
    description: "Upload your resume.",
    backgroundImage: applicantCard2,
  },
  {
    id: 3,
    title: "STEP 3",
    description: "Ace the interview and receive feedback",
    backgroundImage: applicantCard3,
  },
];

const Works = () => {
  const { userRole } = useUserRole();
  const steps =
    userRole === "recruiter" ? stepsEmployerData : stepsCandidateData;

  return (
    <div className="container  max-w-screen-xl  mx-auto font-bold flex flex-col my-10 py-10">
      <div className="flex flex-row text-center items-center justify-center mb-10">
        <img src={whiteStar} alt="" className="w-8 mr-5" />
        <h1 className="text-white text-4xl">
          <span className="font-cinzel text-5xl">H</span>OW{" "}
          <span className="font-cinzel text-5xl">I</span>T{" "}
          <span className="font-cinzel text-5xl">w</span>ORKS ?
        </h1>
        <img src={whiteStar} alt="" className="w-8 ml-5" />
      </div>

      <div className="w-full flex flex-wrap justify-between ">
        <div className="w-full flex justify-center md:justify-between flex-wrap gap-4">
          {steps.map((step, index) => {
            const firstWord = step.title.split(" ")[0];
            const restOfTitle = step.title.split(" ").slice(1).join(" ");

            return (
              <div key={index}>
                <div
                  className="shadow-lg p-8"
                  style={{
                    backgroundImage: `url(${step.backgroundImage})`,
                    backgroundSize: "100% 100%",
                    width: "400px",
                    height: "566px",
                  }}
                >
                  <h3 className="text-2xl font-bold text-white mb-4 text-center">
                    <span className="font-cinzel">{firstWord}</span>{" "}
                    {restOfTitle}
                  </h3>
                  <p className="text-center text-white">{step.description}</p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Works;
