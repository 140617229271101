import React from "react";
import scroll from "../../../assets/services/scroll.png";
import image1 from "../../../assets/services/image1.png";
import image2 from "../../../assets/services/image2.png";
import image3 from "../../../assets/services/image3.png";
import image4 from "../../../assets/services/image4.png";
import image5 from "../../../assets/services/image5.png";

const ScrollSection = () => {
  return (
    <>
      <h1 className="text-white text-center text-4xl font-bold mb-4">
        Why WizardHire?
      </h1>
      <div className="flex justify-center items-center min-h-screen bg-black text-2xl">
        {/* Scroll Container */}
        <div
          className="relative bg-center bg-no-repeat bg-contain p-8"
          style={{
            backgroundImage: `url(${scroll})`,
            backgroundSize: "100% 100%",
            padding: "170px 300px",
          }}
        >
          {/* Transparent Background for Text */}
          <div className="relative text-white p-6 text-center space-y-6  rounded-lg">
            <h2 className="text-3xl font-bold mb-4">Why Use WizardHire?</h2>

            {/* List Items */}
            <ul className="text-left list-disc ml-8 space-y-4">
              <li>
                <strong>Time and Resource Savings:</strong> Automate initial
                screenings to focus on top candidates, reducing your HR team's
                workload.
              </li>
              <li>
                <strong>Scalable Hiring Process:</strong> Handle high volumes of
                applicants seamlessly, no matter the size of your company or the
                number of positions you’re hiring for.
              </li>
              <li>
                <strong>Increased Hiring Accuracy:</strong> Benefit from
                unbiased AI screening and flagging of potential red flags,
                allowing you to focus on the most qualified candidates.
              </li>
              <li>
                <strong>Enhanced Credibility and Trust:</strong> By
                incorporating AI-driven resume checks and consistency
                monitoring, you build a hiring process rooted in transparency,
                fairness, and integrity.
              </li>
              <li>
                <strong>Fraud Prevention:</strong> The built-in resume fraud
                detection system significantly reduces the risk of hiring
                candidates with misrepresented qualifications or experience.
              </li>
              <li>
                <strong>Customizable and Flexible Solutions:</strong> Tailor
                WizardHire to your company’s hiring needs and maintain full
                control over which candidates get flagged and what discrepancies
                matter most.
              </li>
              <li>
                <strong>Constructive Applicant Feedback:</strong> Candidates who
                don’t make it receive constructive feedback, leaving them with a
                positive impression of your company and encouraging them to
                apply again in the future.
              </li>
            </ul>
          </div>

          {/* Characters on sides */}
          <div className="absolute right-1/4 top-[270px] block ">
            <img
              src={image1}
              alt="Character Left"
              className="w-[140px] h-auto float-right"
            />
          </div>
          <div className="absolute left-1/4 top-[380px] block ">
            <img
              src={image2}
              alt="Character Right"
              className="w-[140px] h-auto float-left"
            />
          </div>
          <div className="absolute right-1/4 top-1/2 ">
            <img
              src={image3}
              alt="Character Bottom Left"
              className="w-[140px] h-auto float-right"
            />
          </div>
          <div className="absolute left-1/4 bottom-[480px] ">
            <img
              src={image4}
              alt="Character Bottom Right"
              className="w-[140px] h-auto float-left"
            />
          </div>
          <div className="absolute right-1/4 bottom-1/4 ">
            <img
              src={image5}
              alt="Character Bottom Right"
              className="w-[140px] h-auto float-right"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ScrollSection;
