import React, { useEffect, useState } from "react";
import { BiSolidSun, BiSolidMoon } from "react-icons/bi";

const DarkMode = () => {
  const [theme, setTheme] = useState(
    localStorage.getItem("theme") ? localStorage.getItem("theme") : "light"
  );

  useEffect(() => {
    const element = document.documentElement;
    if (theme === "dark") {
      element.classList.add("dark");
      localStorage.setItem("theme", "dark");
    } else {
      element.classList.remove("dark");
      localStorage.setItem("theme", "light");
    }
  }, [theme]);

  return (
    <div>
      {theme === "light" ? (
        <BiSolidSun
          onClick={() => setTheme("dark")}
          className="cursor-pointer text-white" // Ensure the icon is visible in both themes
        />
      ) : (
        <BiSolidMoon
          onClick={() => setTheme("light")}
          className="cursor-pointer text-pure-black" // Adjust the color for visibility in dark mode
        />
      )}
    </div>
  );
};

export default DarkMode;
