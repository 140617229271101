import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import DOMPurify from "dompurify";
import Modal from "react-modal";
import { toast } from "react-toastify";

// Set the app element for accessibility purposes
Modal.setAppElement("#root");

// Custom styles for the modal
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "500px",
    padding: "20px",
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.75)",
  },
};

const EmployerSingleVacancy = () => {
  const params = useParams();

  const [vacancyInformation, setVacancyInformation] = useState(null);
  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);
  const [vacancyToDelete, setVacancyToDelete] = useState(null);

  const navigate = useNavigate();

  const getVacancy = async () => {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/api/v1/vacancy/getSingleVacancy/${params.id}`
    );
    if (data.success === true) {
      setVacancyInformation(data.singleVacancy);
    }
  };

  useEffect(() => {
    getVacancy();
  }, []);

  const handleDelete = (id) => {
    setVacancyToDelete(id);
    setDeleteModalIsOpen(true);
  };

  const handleConfirmDelete = async () => {
    try {
      const { data } = await axios.delete(
        `${process.env.REACT_APP_API_URL}/api/v1/vacancy/deleteVacancy/${vacancyToDelete}`
      );
      if (data?.success) {
        setDeleteModalIsOpen(false);
        setVacancyToDelete(null);
        toast.success(data?.message);

        navigate("/dashboard/employer/vacancyInfromation");
      }
    } catch (error) {
      console.error(error);
    }
  };
  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  return (
    <>
      <div className="max-w-2xl mx-auto bg-white shadow-md rounded-md overflow-hidden">
        {vacancyInformation ? (
          <div className="p-6">
            <h2 className="text-2xl font-bold mb-2">
              {vacancyInformation.jobTitle}
            </h2>

            <p className="text-gray-600 mb-2">
              {vacancyInformation.companyName}
            </p>

            <p className="text-gray-600 mb-4">
              {vacancyInformation.experiencelevel.value === 0
                ? "Entry level"
                : `${vacancyInformation.experiencelevel.value} ${vacancyInformation.experiencelevel.unit}`}
            </p>
            <p className="text-gray-600 mb-4">{vacancyInformation.jobType}</p>
            <p className="text-gray-600 mb-4">
              {vacancyInformation.skills.join(", ")}
            </p>
            <div className="mb-4 text-start">
              <h3 className="text-lg font-semibold">Job Description</h3>
              <div
                className="job-description text-gray-800"
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(vacancyInformation.jobDescription),
                }}
              ></div>
            </div>
            <p className="py-5">
              Active from {formatDate(vacancyInformation.startDate)} to{" "}
              {formatDate(vacancyInformation.endDate)}
            </p>

            <button
              className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-700 transition duration-300 mx-2"
              onClick={() =>
                navigate(
                  `/dashboard/employer/editVacancyInfromation/${params.id}`
                )
              }
            >
              Edit
            </button>
            <button
              className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-700 transition duration-300 mx-2"
              onClick={() => handleDelete(params.id)}
            >
              Delete
            </button>
          </div>
        ) : (
          <div>Loading ...</div>
        )}
      </div>

      <Modal
        isOpen={deleteModalIsOpen}
        onRequestClose={() => setDeleteModalIsOpen(false)}
        contentLabel="Confirm Delete"
        style={customStyles}
      >
        <h2 className="text-2xl font-bold mb-6">Confirm Deletion</h2>
        <p className="mb-4">
          Are you sure you want to delete this vacancy? This action cannot be
          undone.
        </p>
        <div className="flex justify-end">
          <button
            onClick={handleConfirmDelete}
            className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-700 transition duration-300"
          >
            Confirm
          </button>
          <button
            onClick={() => setDeleteModalIsOpen(false)}
            className="bg-gray-500 text-white px-4 py-2 rounded ml-2 hover:bg-gray-700 transition duration-300"
          >
            Cancel
          </button>
        </div>
      </Modal>
    </>
  );
};

export default EmployerSingleVacancy;
