import React from "react";
import whiteStar from "../../assets/images/whiteStar.png";
import aboutTextButton from "../../assets/about/aboutTextButton.png";

const HeroSection = () => {
  return (
    <div className="container max-w-screen-xl mx-auto flex flex-col my-10 py-10 ">
      <div className="flex  flex-row text-center items-center justify-center  font-bold mb-10">
        <img src={whiteStar} alt="" className="w-8 mr-5" />
        <h1 className="text-white text-4xl">
          <span className="font-cinzel text-5xl">A</span>BOUT{" "}
          <span className="font-cinzel text-5xl">w</span>IZARDHIRE
        </h1>
        <img src={whiteStar} alt="" className="w-8 ml-5" />
      </div>
      <div
        className="w-full p-10 text-center"
        style={{
          backgroundImage: `url(${aboutTextButton})`,
          backgroundSize: "100% 100%",
        }}
      >
        <p className="text-white text-2xl ">
          At WizardHire, we're revolutionizing recruitment by blending AI-driven
          innovation with a deep commitment to fairness and security. What
          started as a serendipitous meeting between co-founders Francine
          Gutwilik and Adhiraj Dhungana in Winnipeg quickly evolved into a
          partnership fueled by the desire to create a platform that transcends
          borders, biases, and inefficiencies in the hiring process.
        </p>
      </div>
    </div>
  );
};

export default HeroSection;
