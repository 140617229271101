import React from "react";
import logoHat from "../../../assets/selection/logoHat.png";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="py-8 bg-pure-black dark:bg-primary-dark text-white">
      <div className="container mx-auto w-full">
        {/* Footer Links Section */}
        <div className="md:flex justify-between text-center">
          {/* Logo Section */}
          <div className="text-center flex justify-center items-center sm:col-span-full">
            <img src={logoHat} alt="Logo" className="w-24" />
          </div>

          {/* Company Links */}
          <div className="my-4">
            <ul className="list-none">
              <li className="mb-4 text-2xl mb-2">
                <Link
                  to="/"
                  className="hover:text-gray-400 transition duration-300 "
                >
                  COMPANY
                </Link>
              </li>
              <li className=" text-lg">
                <Link
                  to="/"
                  className="hover:text-gray-400 transition duration-300"
                >
                  About Us
                </Link>
              </li>
              <li className=" text-lg">
                <Link
                  to="/"
                  className="hover:text-gray-400 transition duration-300"
                >
                  Contact Us
                </Link>
              </li>
              <li className=" text-lg">
                <Link
                  to="/"
                  className="hover:text-gray-400 transition duration-300"
                >
                  Media
                </Link>
              </li>
            </ul>
          </div>

          {/* Resources Links */}
          <div className="my-4">
            <ul className="list-none">
              <li className="mb-4 text-2xl mb-2">
                <Link
                  to="/"
                  className="hover:text-gray-400 transition duration-300 "
                >
                  RESOURCES
                </Link>
              </li>
              <li className="text-lg">
                <Link
                  to="/"
                  className="hover:text-gray-400 transition duration-300"
                >
                  Blog
                </Link>
              </li>
              <li className="text-lg">
                <Link
                  to="/faq"
                  className="hover:text-gray-400 transition duration-300"
                >
                  FAQ
                </Link>
              </li>
              <li className="text-lg">
                <Link
                  to="/"
                  className="hover:text-gray-400 transition duration-300"
                >
                  Guides
                </Link>
              </li>
            </ul>
          </div>

          {/* Services Links */}
          <div className="my-4">
            <ul className="list-none">
              <li className="mb-4 text-2xl mb-2">
                <Link
                  to="/"
                  className="hover:text-gray-400 transition duration-300 "
                >
                  SERVICES
                </Link>
              </li>
              <li className="text-lg">
                <Link
                  to="/"
                  className="hover:text-gray-400 transition duration-300"
                >
                  For Employers
                </Link>
              </li>
              <li className="text-lg">
                <Link
                  to="/"
                  className="hover:text-gray-400 transition duration-300"
                >
                  For Job Seekers
                </Link>
              </li>
              <li className="text-lg">
                <Link
                  to="/"
                  className="hover:text-gray-400 transition duration-300"
                >
                  Plans & Pricing
                </Link>
              </li>
            </ul>
          </div>

          {/* Legal Links */}
          <div className="my-4">
            <ul className="list-none">
              <li className="mb-4 text-2xl mb-2">
                <Link
                  to="/"
                  className="hover:text-gray-400 transition duration-300 "
                >
                  LEGAL
                </Link>
              </li>
              <li className="text-lg">
                <Link
                  to="/"
                  className="hover:text-gray-400 transition duration-300"
                >
                  Privacy Policy
                </Link>
              </li>
              <li className="text-lg">
                <Link
                  to="/"
                  className="hover:text-gray-400 transition duration-300"
                >
                  Terms of Service
                </Link>
              </li>
              <li className="text-lg">
                <Link
                  to="/"
                  className="hover:text-gray-400 transition duration-300"
                >
                  Cookie Settings
                </Link>
              </li>
            </ul>
          </div>
        </div>

        {/* Footer Copyright and Social Media Section */}
        <hr className="container mx-auto mb-5" />
        <div className=" text-center">
          <p className="text-sm mb-4">
            &copy; 2024 WizardHire. All rights reserved.
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
