import React, { useState } from "react";
import serviceHero from "../../../assets/services/serviceHero.png";
import headerButtonTextBox from "../../../assets/services/headerButtonTextBox.png";
import startNowButton from "../../../assets/services/startNowButton.png";
import startNowButtonGlow from "../../../assets/services/startNowButtonGlow.png";
import button from "../../../assets/images/button.png";
import image1 from "../../../assets/services/image1.png";
import image2 from "../../../assets/services/image2.png";

export const ServiceHeroSection = () => {
  const [isButtonHovered, setIsButtonHovered] = useState(false);

  return (
    <>
      <div className="flex flex-col text-sm md:text-2xl text-white">
        {/* Service Hero and Text Box Section Container */}
        <div className="relative">
          {/* Service Hero Image */}
          <div className="overflow-hidden rounded-md shadow-lg mx-auto">
            <img
              src={serviceHero}
              alt="Diverse Office Team"
              className="w-full h-full object-cover"
            />
          </div>

          {/* Overlapping Button */}
          <div className="absolute w-full top-1/2 md:top-2/3 flex justify-center z-10">
            <button
              className="text-xl font-bold flex items-center justify-center"
              style={{
                backgroundImage: `url(${button})`,
                backgroundSize: "100% 100%",
                padding: "20px 40px",
                backgroundRepeat: "no-repeat",
              }}
            >
              Effortless Hiring, Powered by AI!
            </button>
          </div>

          {/* Header Button Text Box with overlapping images */}
          <div
            className="relative rounded-lg text-center mt-[-12px] md:mt-[-26px]" // Negative margin to bring closer to the image
            style={{
              backgroundImage: `url(${headerButtonTextBox})`,
              backgroundSize: "100% 100%",
              backgroundPosition: "center",
            }}
          >
            <p className="text-lg p-10 md:p-8 mb-6 pt-12">
              Let our custom-built AI streamline your recruitment process,
              ensuring you find the best talent faster than ever before.
            </p>

            <button
              className="font-bold py-4 px-8 rounded-full"
              onMouseEnter={() => setIsButtonHovered(true)}
              onMouseLeave={() => setIsButtonHovered(false)}
              style={{
                backgroundImage: `url(${
                  isButtonHovered ? startNowButtonGlow : startNowButton
                })`,
                backgroundSize: "100% 100%",
              }}
            >
              START NOW
            </button>

            {/* Overlapping Images */}
            <div className="absolute w-full top-[100%] flex justify-evenly mt-[-60px]">
              <img
                src={image1}
                alt="Wizard 1"
                className="h-24"
                style={{ zIndex: 1 }} // Ensure the images are on top of the text box
              />
              <img
                src={image2}
                alt="Wizard 2"
                className="h-24"
                style={{ zIndex: 1 }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
