import React from "react";

import { ServiceHeroSection } from "../components/employer/ServicesSection/ServiceHeroSection";
import Layout from "../components/Layout/Layout";
import { useUserRole } from "../context/userRoleContext";
import { ServiceCandidateFeatures } from "../components/candidate/Service/ServiceCandidateFeatures";
import Pricing from "../components/employer/ServicesSection/PricingSection";
import FinalCta from "../components/home/FinalCta/FinalCta";
import ScrollSection from "../components/employer/ServicesSection/ScrollSection";

const Services = () => {
  const { userRole } = useUserRole();
  return (
    <>
      <Layout title="WizardHire AI - Services">
        <div className="container  max-w-screen-xl mx-auto px-4">
          {userRole === "recruiter" ? (
            <>
              <ServiceHeroSection />
              <Pricing />
              <ScrollSection />
              <div className=" text-white py-8 text-center">
                <p className="text-lg font-medium">
                  Need more details <span className="font-cinzel">?</span>
                </p>
                <p className="text-lg font-semibold">
                  Please visit our{" "}
                  <a href="/faq" className="underline">
                    FAQ
                  </a>{" "}
                  section !
                </p>
              </div>
              <FinalCta />
            </>
          ) : (
            <ServiceCandidateFeatures />
          )}
        </div>
      </Layout>
    </>
  );
};
export default Services;
