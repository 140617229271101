import React from "react";
import whiteStar from "../../../assets/images/whiteStar.png";
import textBox from "../../../assets/vision/textBox.png";
import employerVision from "../../../assets/vision/employerVision.png";
import applicantVision from "../../../assets/vision/applicantVision.png";
import { useUserRole } from "../../../context/userRoleContext";

const Vision = () => {
  const { userRole } = useUserRole();
  const vision = userRole === "recruiter" ? employerVision : applicantVision;
  return (
    <div className="container  max-w-screen-xl  mx-auto flex flex-col my-10">
      <div className="flex flex-row text-center items-center justify-center mb-5">
        <img src={whiteStar} alt="" className="w-8 mr-5" />{" "}
        <h1 className="text-white text-4xl">
          <span className="font-cinzel text-5xl">O</span>UR{" "}
          <span className="font-cinzel text-5xl">V</span>ISION{" "}
        </h1>{" "}
        <img src={whiteStar} alt="" className="w-8 ml-5" />
      </div>

      <div className=" mx-auto flex flex-wrap justify-center text-2xl">
        <img
          src={vision} // Replace with your image URL
          alt="Diverse team collaborating"
          className="w-3/4 rounded-md mb-8"
        />
        <div
          className="text-white shadow-lg text-center"
          style={{
            backgroundImage: `url(${textBox})`,
            backgroundSize: "cover", // or "contain" depending on your needs
            backgroundPosition: "center", // Ensures the image is centered
            backgroundRepeat: "no-repeat", // Prevents the image from repeating
            width: "100%", // Set a specific width if needed
            height: "auto", // Set a specific height
            borderRadius: "20px",
            padding: "40px 0px",
          }}
        >
          {userRole === "recruiter" ? (
            <>
              <p className="mb-4">
                At WizardHire, our mission is to remove bias from hiring and
                create a fair, transparent recruitment process.
              </p>
              <p className="mb-4">
                We use AI technology to help employers find the best candidates
                based on skills and potential, ensuring diversity and
                inclusivity in every hire.
              </p>
              <p>
                Our goal is to make hiring efficient and accurate, so businesses
                can build strong, innovative teams while giving every candidate
                an equal chance to succeed.
              </p>
            </>
          ) : (
            <>
              <p className="mb-4">
                At WizardHire, our mission is to give every applicant an equal
                and fair chance to showcase their skills.
              </p>
              <p className="mb-4">
                We use AI-driven technology to remove bias from the hiring
                process, ensuring that your qualifications and potential are
                what matter most.
              </p>
              <p>
                Our platform is designed to connect you with opportunities where
                your talents can shine, helping you advance your career based on
                merit, not perceptions.
              </p>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Vision;
