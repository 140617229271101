import React from "react";
import Layout from "../components/Layout/Layout";
import HeroSection from "../components/aboutus/HeroSection";
import TeamSection from "../components/aboutus/TeamSection";
import AboutTrustSection from "../components/aboutus/AboutTrustSection";
import WhyWizardHireSection from "../components/aboutus/WhyWizardHireSection";

const About = () => {
  return (
    <main className="bg-neutral-dark text-white dark:bg-neutral-light">
      <Layout title="WizardHire AI - About Us">
        <section>
          <HeroSection />
          <TeamSection />
          <AboutTrustSection />
          <WhyWizardHireSection />
        </section>
      </Layout>
    </main>
  );
};

export default About;
