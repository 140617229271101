import React, { useState } from "react";
import girl from "../../assets/selection/girl.png";
import button from "../../assets/images/button.png";
import textBox from "../../assets/selection/textBox.png";
import buttonHover from "../../assets/images/buttonHover.png";
import textBoxHover from "../../assets/selection/textBoxHover.png";
import logoHat from "../../assets/selection/logoHat.png";
import logoHatGlow from "../../assets/selection/logoHatGlow.png";
import "./Selection.css";
import { useNavigate } from "react-router-dom";
import { useUserRole } from "../../context/userRoleContext";
import DarkMode from "../../components/Layout/Header/DarkMode";

const SelectionPage = () => {
  const [isEmployerTitleHovered, setIsEmployerTitleHovered] = useState(false);
  const [isEmployerDescHovered, setIsEmployerDescHovered] = useState(false);
  const [isApplicantTitleHovered, setIsApplicantTitleHovered] = useState(false);
  const [isApplicantDescHovered, setIsApplicantDescHovered] = useState(false);

  const navigate = useNavigate();
  const { updateRole } = useUserRole();

  const handleSelect = (role) => {
    if (role) {
      updateRole(role);
      navigate("/home");
    }
  };

  return (
    <>
      <div
        className="bg-neutral-dark dark:bg-neutral-light text-white font-futura"
        style={{ minHeight: "100vh", display: "flex", flexDirection: "column" }}
      >
        {/* Header section */}
        <div className="flex justify-end p-2 ">
          <DarkMode />
        </div>

        {/* Main content area */}
        <div
          className="container max-w-screen-xl mx-auto flex flex-col items-center justify-center "
          style={{ flexGrow: 1 }}
        >
          <div className="text-center mb-8 dark:text-primary-dark">
            <div className="flex justify-center items-center">
              <div className="animate-imageSwap" style={{ width: "70px" }}>
                <img src={logoHat} alt="Logo Hat" />
                <img src={logoHatGlow} alt="Logo Hat Glow" />
              </div>
            </div>
            <h2 className="text-3xl font-semibold">
              <span className="text-4xl font-cinzel">W</span>HICH{" "}
              <span className="text-4xl font-cinzel">O</span>NE
            </h2>
            <h2 className="text-3xl font-semibold">
              <span className="text-4xl font-cinzel">R</span>EPRESENTS{" "}
              <span className="text-4xl font-cinzel">Y</span>OU?
            </h2>
          </div>

          <div className="flex flex-col md:flex-row gap-8">
            {/* Button for Employer */}
            <button
              className={"text-center w-full"}
              onClick={() => handleSelect("recruiter")}
            >
              <h3
                className="md:w-fit mx-auto bg-cover text-xl font-semibold wizard-background "
                onMouseEnter={() => {
                  setIsEmployerTitleHovered(true);
                }}
                onMouseLeave={() => {
                  setIsEmployerTitleHovered(false);
                }}
                style={{
                  backgroundImage: `url(${
                    isEmployerTitleHovered ? buttonHover : button
                  })`,
                  padding: "15px 40px",
                }}
              >
                <span className="text-2xl font-cinzel">A</span>N{" "}
                <span className="text-2xl font-cinzel">E</span>MPLOYER
              </h3>
              <div
                className="text-lg leading-relaxed wizard-background font-apercu"
                onMouseEnter={() => {
                  setIsEmployerDescHovered(true);
                }}
                onMouseLeave={() => {
                  setIsEmployerDescHovered(false);
                }}
                style={{
                  backgroundImage: `url(${
                    isEmployerDescHovered ? textBoxHover : textBox
                  })`,
                  padding: "45px",
                }}
              >
                <p className="mb-3">
                  WizardHire is transforming recruitment with cutting-edge AI.
                </p>
                <p>
                  Our platform streamlines your hiring process, delivering top
                  talent faster and reducing bias with custom AI built for your
                  needs. Save time, hire smarter, and watch your workforce grow!
                </p>
              </div>
            </button>

            <div className="flex justify-center">
              <img src={girl} alt="Anime Character" className="w-full " />
            </div>

            {/* Button for Applicant */}
            <button
              className="text-center w-full"
              onClick={() => handleSelect("candidate")}
            >
              <h3
                className="md:w-fit mx-auto bg-cover text-xl font-semibold wizard-background transition-transform duration-300 transform"
                onMouseEnter={() => {
                  setIsApplicantTitleHovered(true);
                }}
                onMouseLeave={() => {
                  setIsApplicantTitleHovered(false);
                }}
                style={{
                  backgroundImage: `url(${
                    isApplicantTitleHovered ? buttonHover : button
                  })`,
                  padding: "15px 40px",
                }}
              >
                <span className="text-2xl font-cinzel">A</span>N{" "}
                <span className="text-2xl font-cinzel">A</span>PPLICANT
              </h3>
              <div
                className="text-lg leading-relaxed wizard-background font-apercu"
                onMouseEnter={() => {
                  setIsApplicantDescHovered(true);
                }}
                onMouseLeave={() => {
                  setIsApplicantDescHovered(false);
                }}
                style={{
                  backgroundImage: `url(${
                    isApplicantDescHovered ? textBoxHover : textBox
                  })`,
                  padding: "45px",
                }}
              >
                <p className="mb-3">
                  WizardHire makes applying for jobs easy, fun, and fair.
                </p>
                <p>
                  Using AI, we provide real-time feedback and ensure every
                  application is reviewed thoroughly. Get your dream job faster
                  with a platform built to give everyone an equal shot!
                </p>
              </div>
            </button>
          </div>
        </div>

        {/* Footer */}
        <div
          className="bg-pure-black dark:bg-primary-dark"
          style={{ height: "7vh" }}
        ></div>
      </div>
    </>
  );
};

export default SelectionPage;
