import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import DOMPurify from "dompurify";
import Header from "./Layout/Header/Header";
import Modal from "react-modal";
import { toast } from "react-toastify";

Modal.setAppElement("#root");
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "500px",
    padding: "20px",
    transition: "opacity 0.2s ease-in-out",
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.75)",
    zIndex: "1000",
    transition: "opacity 0.2s ease-in-out",
  },
};

const SingleVacancy = () => {
  const params = useParams();
  const [vacancyInformation, setVacancyInformation] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    vacancyId: "",
    resume: "",
  });

  const navigate = useNavigate();

  const getVacancy = async () => {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/api/v1/vacancy/getSingleVacancy/${params.id}`
    );
    if (data.success === true) {
      setVacancyInformation(data.singleVacancy);
    }
  };
  const handleInterview = async (e) => {
    e.preventDefault();
    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/v1/applicant/createApplicant`,
        {
          firstName: formData.firstName,
          lastName: formData.lastName,
          email: formData.email,
          vacancyId: params.id,
          resume: formData.resume,
        }
      );

      if (data.success) {
        console.log("Infromation", data?.savedApplicant?._id);
        navigate(`/interview/${params.id}`, {
          state: { applicantId: data?.savedApplicant?._id },
        });
      }
    } catch (error) {
      toast.error("Some went wrong");
    }
  };
  const getcandidateData = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/v1/user/displayUser`
      );
      if (response.data.success) {
        setFormData({
          firstName: response.data.user.firstName,
          lastName: response.data.user.lastName,
          email: response.data.user.email,
          resume: response.data.user.resume,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getVacancy();
  }, []);

  useEffect(() => {
    getcandidateData();
  }, []);

  const openModal = () => {
    setModalIsOpen(true);
  };
  const closeModal = () => {
    setModalIsOpen(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  return (
    <>
      <div className="max-w-2xl mx-auto bg-white shadow-md rounded-md overflow-hidden">
        <Header />
        {vacancyInformation ? (
          <div className="p-6">
            <h2 className="text-2xl font-bold mb-2">
              {vacancyInformation.jobTitle}
            </h2>

            <p className="text-gray-600 mb-2">
              {vacancyInformation.companyName}
            </p>

            <p className="text-gray-600 mb-4">
              {vacancyInformation.experiencelevel.value === 0
                ? "Entry level"
                : `${vacancyInformation.experiencelevel.value} ${vacancyInformation.experiencelevel.unit}`}
            </p>
            <p className="text-gray-600 mb-4">{vacancyInformation.jobType}</p>
            <p className="text-gray-600 mb-4">
              {vacancyInformation.skills.join(", ")}
            </p>
            <div className="mb-4 text-start">
              <h3 className="text-lg font-semibold">Job Description</h3>
              <div
                className="job-description text-gray-800"
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(vacancyInformation.jobDescription),
                }}
              ></div>
            </div>

            <button
              className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-700 transition duration-300 mx-2"
              onClick={openModal}
            >
              Apply Now
            </button>
          </div>
        ) : (
          <div>Loading ...</div>
        )}
      </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Create Applicant"
        style={customStyles}
        // className="custom-modal-content custom-modal-overlay"
      >
        <h2 className="text-xl mb-4">Apply</h2>
        <form onSubmit={handleInterview}>
          <div>
            <label className="block mb-1" htmlFor="firstName">
              First Name:
            </label>
            <input
              type="text"
              id="firstName"
              name="firstName"
              value={formData.firstName}
              onChange={handleChange}
              className="w-full px-3 py-2 border rounded"
            />
          </div>
          <div>
            <label className="block mb-1" htmlFor="lastName">
              Last Name:
            </label>
            <input
              type="text"
              id="lastName"
              name="lastName"
              value={formData.lastName}
              onChange={handleChange}
              className="w-full px-3 py-2 border rounded"
            />
          </div>
          <div>
            <label className="block mb-1" htmlFor="email">
              Email:
            </label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              className="w-full px-3 py-2 border rounded"
            />
          </div>
          <div>
            <label className="block mb-1" htmlFor="resume">
              Resume:
            </label>
            <input
              type="text"
              id="resume"
              name="resume"
              value={formData.resume}
              onChange={handleChange}
              className="w-full px-3 py-2 border rounded"
            />
          </div>
          <div className="flex justify-end">
            <button
              type="button"
              onClick={closeModal}
              className="mr-2 px-4 py-2 border rounded bg-gray-300"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="px-4 py-2 bg-blue-500 text-white rounded"
            >
              Take Interview
            </button>
          </div>
        </form>
      </Modal>
    </>
  );
};

export default SingleVacancy;
