import React from "react";
import adhiraj from "../../assets/about/adhiraj.png";
import francine from "../../assets/about/francine.png";

const TeamSection = () => {
  return (
    <div className="container max-w-screen-xl mx-auto text-center py-10">
      <h1 className="text-5xl font-bold mb-8">
        {" "}
        <span className="font-cinzel"> T</span>HE{" "}
        <span className="font-cinzel"> T</span>EAM
      </h1>
      <div className="flex flex-col md:flex-row justify-evenly items-center gap-12">
        <div className="text-center">
          <img
            src={francine}
            alt="Francine Gutwilik"
            className="w-40 h-40 rounded-full mx-auto mb-4"
          />
          <h2 className="text-4xl font-semibold">Francine Gutwilik</h2>
          <h3 className="text-3xl text-white">CEO</h3>
          <p className="mt-4  mx-auto text-2xl">
            Francine, our CEO, is a seasoned leader with over 20 years of
            experience in HR, recruitment, and startups. Having successfully led
            four companies to major exits, including LivePerson (LPSN Nasdaq)
            and DataSynapse (now TIBCO), she brings a wealth of strategic
            insight and passion for optimizing the recruitment process. Her
            vision for WizardHire is clear—empower employers to find the best
            talent faster and more fairly, while providing job seekers with a
            platform that recognizes their true potential.
          </p>
        </div>
        <div className=" text-center">
          <img
            src={adhiraj}
            alt="Adhiraj Dhungana"
            className="w-40 h-40 rounded-full mx-auto mb-4"
          />
          <h2 className="text-4xl font-semibold">Adhiraj Dhungana</h2>
          <h3 className="text-3xl text-white">COO</h3>
          <p className="mt-4  mx-auto text-2xl">
            Adhiraj, our COO, is the technical genius behind the WizardHire
            platform. With a scientific background in physics, his expertise in
            coding and AI development ensures that our technology is not only
            cutting-edge but also bias-free. In a world where unconscious bias
            can skew hiring decisions, WizardHire's AI is designed to evaluate
            candidates based on their skills and qualifications alone—leaving
            behind the pitfalls of traditional job boards like LinkedIn and
            Indeed.
          </p>
        </div>
      </div>
    </div>
  );
};

export default TeamSection;
