import React, { useState, useEffect, useRef } from "react";
import "quill/dist/quill.snow.css";
import Quill from "quill";
import axios from "axios";
import { toast } from "react-toastify";
import Modal from "react-modal";
import { useNavigate } from "react-router-dom";

// Set the app element for the modal (helps with accessibility)
Modal.setAppElement("#root");

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)",
    width: "500px",
    padding: "20px",
    borderRadius: "8px",
    boxShadow: "0 4px 8px rgba(0,0,0,0.2)",
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.75)",
    zIndex: "1000",
  },
};

const EmployerCreateVacancy = () => {
  const [formData, setFormData] = useState({
    companyName: "",
    jobTitle: "",
    experienceValue: "",
    experienceUnit: "months",
    jobType: "full-time",
    skills: [], // Initialize as an array
    jobDescription: "",
    startDate: "",
    endDate: "",
  });
  const navigate = useNavigate();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const quillRef = useRef(null);
  const editorRef = useRef(null);

  useEffect(() => {
    if (quillRef.current) {
      editorRef.current = new Quill(quillRef.current, {
        theme: "snow",
        modules: {
          toolbar: [
            [{ header: [1, 2, false] }],
            ["bold", "italic", "underline"],
            ["blockquote", "code-block"],
            [{ list: "ordered" }],
            ["link"],
            ["clean"],
          ],
        },
      });

      editorRef.current.on("text-change", () => {
        setFormData((prevFormData) => ({
          ...prevFormData,
          jobDescription: editorRef.current.root.innerHTML,
        }));
      });
    }
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleExperienceUnitChange = (e) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      experienceUnit: e.target.value,
    }));
  };

  const handleSkillsChange = (e) => {
    const skillsString = e.target.value;
    // Ensure skills are always stored as an array
    const skillsArray = skillsString.split(",").map((skill) => skill.trim());
    setFormData((prevFormData) => ({
      ...prevFormData,
      skills: skillsArray,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const {
        companyName,
        jobTitle,
        experienceValue,
        experienceUnit,
        jobType,
        skills,
        jobDescription,
        startDate,
        endDate,
      } = formData;

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/v1/vacancy/createVacancy`,
        {
          companyName,
          jobTitle,
          experiencelevel: {
            value: Number(experienceValue),
            unit: experienceUnit,
          },
          jobType,
          skills,
          jobDescription,
          startDate,
          endDate,
        }
      );

      if (response.data.success) {
        toast.success(response.data.message);
        setFormData({
          companyName: "",
          jobTitle: "",
          experienceValue: "",
          experienceUnit: "months",
          jobType: "full-time",
          skills: [], // Reset to an empty array
          jobDescription: "",
          startDate: "",
          endDate: "",
        });
        editorRef.current.root.innerHTML = ""; // Clear Quill editor
        navigate("/dashboard/employer/vacancyInfromation");
      }
    } catch (error) {
      toast.error(error.response?.data?.message || "An error occurred");
      console.error("Error creating vacancy:", error);
    }
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className=" bg-white shadow-md rounded-lg overflow-hidden my-4 p-4">
      <form onSubmit={handleSubmit}>
        <div className="flex gap-3">
          <div className="mb-4 w-full">
            <label className="block text-gray-700 text-sm font-bold mb-2">
              Company Name
            </label>
            <input
              type="text"
              name="companyName"
              value={formData.companyName}
              onChange={handleChange}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              required
            />
          </div>
          <div className="mb-4 w-full">
            <label className="block text-gray-700 text-sm font-bold mb-2">
              Job Title
            </label>
            <input
              type="text"
              name="jobTitle"
              value={formData.jobTitle}
              onChange={handleChange}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              required
            />
          </div>
        </div>
        <div>Location</div>
        <div>Number of Vacancy</div>
        <div className="flex">
          <div className="flex w-full gap-2">
            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2">
                Experience Value
              </label>
              <input
                type="number"
                name="experienceValue"
                value={formData.experienceValue}
                onChange={handleChange}
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                required
              />
            </div>
            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2">
                Experience Unit
              </label>
              <select
                name="experienceUnit"
                value={formData.experienceUnit}
                onChange={handleExperienceUnitChange}
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                required
              >
                <option value="months">Months</option>
                <option value="years">Years</option>
              </select>
            </div>
          </div>
          <div className="mb-4 w-full">
            <label className="block text-gray-700 text-sm font-bold mb-2">
              Job Type
            </label>
            <select
              name="jobType"
              value={formData.jobType}
              onChange={handleChange}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              required
            >
              <option value="full-time">Full-time</option>
              <option value="part-time">Part-time</option>
              <option value="intern">Intern</option>
            </select>
          </div>
        </div>

        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2">
            Skills (comma-separated) For Eg: communication,leadership
          </label>
          <input
            type="text"
            name="skills"
            value={formData.skills.join(",")} // Convert array to comma-separated string for input value
            onChange={handleSkillsChange}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            required
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2">
            Job Description
          </label>
          <div ref={quillRef} style={{ height: "300px" }}></div>
        </div>
        <div className="mb-4">Responsibilities of the Candidate</div>
        <div className="mb-4">Requirements</div>
        <div className="mb-4">Benefits</div>
        <input
          type="date"
          name="startDate"
          placeholder="Start Date"
          onChange={handleChange}
        />
        <input
          type="date"
          name="endDate"
          placeholder="End Date"
          onChange={handleChange}
        />
        <div className="flex gap-2">
          <button
            type="button"
            onClick={openModal}
            className="mt-3 bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          >
            Preview
          </button>
          <button
            type="submit"
            className="mt-3 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          >
            Create Vacancy
          </button>
        </div>
      </form>

      {/* Modal for Preview */}
      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        contentLabel="Preview"
        style={customStyles}
      >
        <div className="bg-white p-6 rounded-lg shadow-lg max-w-3xl mx-auto space-y-4">
          <h2 className="text-2xl font-semibold mb-4 border-b-2 border-gray-200 pb-2">
            Preview
          </h2>
          <div className="flex flex-col space-y-2">
            <div className="flex items-center">
              <strong className="w-1/3 text-gray-700">Company Name:</strong>
              <span className="ml-2 text-gray-900">{formData.companyName}</span>
            </div>
            <div className="flex items-center">
              <strong className="w-1/3 text-gray-700">Job Title:</strong>
              <span className="ml-2 text-gray-900">{formData.jobTitle}</span>
            </div>
            <div className="flex items-center">
              <strong className="w-1/3 text-gray-700">Experience:</strong>
              <span className="ml-2 text-gray-900">
                {formData.experienceValue} {formData.experienceUnit}
              </span>
            </div>

            <div className="flex items-center">
              <strong className="w-1/3 text-gray-700">Job Type:</strong>
              <span className="ml-2 text-gray-900">{formData.jobType}</span>
            </div>
            <div className="flex items-center">
              <strong className="w-1/3 text-gray-700">Skills:</strong>
              <span className="ml-2 text-gray-900">
                {formData.skills.join(", ")}
              </span>
            </div>
            <div className="border-t border-gray-200 pt-4">
              <strong className="text-gray-700">Job Description:</strong>
              <div
                dangerouslySetInnerHTML={{ __html: formData.jobDescription }}
                className="border p-4 rounded-lg bg-gray-100 mt-2"
              />
            </div>
          </div>
          <button
            onClick={closeModal}
            className="mt-4 bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          >
            Close
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default EmployerCreateVacancy;
